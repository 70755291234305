@if(isSpaceEditPicker){
  <div class="dialog-header">
    <div class="title">{{ "APP.ADDRESS.SELECT" | translate }}</div>
    <div (click)="cancel()" class="close-button">
      <i class="fa-regular fa-xmark"></i>
    </div>
  </div>
}@else{
  <h1 class="title">{{ "APP.ADDRESS.SELECT" | translate }}</h1>
}

<div [formGroup]="form" class="address-picker-dialog-content">
  <div class="address-picker-dialog-fields">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{ "APP.LOCATION_NAME" | translate }}</mat-label>
      <input
        type="text"
        matInput
        formControlName="name"
        placeholder=""
        #search
      />
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{ "APP.LOCATION_ADDRESS" | translate }}*</mat-label>
      <input
        type="text"
        matInput
        formControlName="street"
        placeholder=""
        #streetSearch
      />
    </mat-form-field>
  </div>

  <div class="google-map-container">
    <google-map
      mapId="address-picker-map"
      [zoom]="mapZoom"
      [center]="mapCenter"
      [options]="mapOptions"
      (mapClick)="mapClick($event)"
    >
      <map-advanced-marker
        [position]="markerPosition"
        [options]="markerOptions"
      ></map-advanced-marker>
    </google-map>
  </div>
  <div class="actions-container">
    <button (click)="cancel()" class="actionButton btn btn-white">
      <span class="actions"> {{ "APP.CANCEL" | translate }} </span>
    </button>
    <button (click)="ok()" type="button" class="actionButton btn btn-primary">
      <span class="actions"> {{ "APP.OK" | translate }} </span>
    </button>
  </div>
</div>
