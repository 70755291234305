<div
  #quillContainer
  [class.rsvp-section]="isRSVPSection"
  [class.focused]="showToolbar"
  [class.big-font]="sectionProperty && sectionProperty === 'title'"
  [class.custom-font]="font"
>
  <quill-editor
    #quillEditor
    [theme]=theme
    [(ngModel)]="valueInEditor"
    [modules]="editorConfig"
    [bounds]="'self'"
    [class.toolbar-visible]="showToolbar"
    [class.primary-color]="
      section &&
      section.type === EventRSVPConfirmationPageSectionType.CREATE_EVENT
    "
    (keydown)='onKeyDown($event)'
    [class.snow-theme]='theme === "snow"'
    [class.bubble-theme]='theme === "bubble"'
    (click)="showToolbar = true"
    (onContentChanged)="onContentChanged($event)"
    [ngClass]="{
      'event-edit-textarea event-description body-font custom-quill-container':
        !adminView,
      'admin-quill-container': adminView
    }"
    [ngStyle]="{
      'color': bodyColor,
      'border-color': borderColor,
      'font-family': font,
      'font-weight': fontWeight
  }"
    [class.quill-focused]="showToolbar && theme === 'snow'"
  ></quill-editor>
</div>
